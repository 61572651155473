<template>
    <div
        id="portfolioChooseDate"
        class="draggable"
        :class="[s.container, theme.statusrow.statusrow]"
    >
        <div :class="s.mainColumn">
            <div :class="s.title">
                Balance by Day
            </div>
            <v-date-picker
                v-model="range"
                :is-dark="$store.getters.isThemeDark"
                is-range
                locale="en"
                :masks="masks"
                mode="date"
                :max-date="Date.now()"
                @input="onDatePickerInput"
                :class="s.calendarContainer"
            >
                <template #default="{ inputValue, togglePopover, hidePopover }">
                    <div
                        :class="s.calendar"
                        @click="onDatePickerToggle(togglePopover, hidePopover)"
                    >
                        <span :class="s.dateText">
                            {{ inputValue ? generateDropdownString(inputValue) : 'Period' }}
                        </span>
                        <Icon
                            icon="arrow_down"
                            :class="s.icon"
                        />
                    </div>
                </template>
            </v-date-picker>
        </div>
        <div :class="[theme.statusrow.statusrow, s.row]">
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <div :class="theme.statusrow.subheaderText">Total Account Value</div>
                    <div :class="s.tableValue">
                        {{ quotationAssetCharacter + totalAccountScore.getInt().getSeparatedDigits() }}
                        <span>{{ totalAccountScore.toFixed(quotationPrecision).fraction() }}</span>
                    </div>
                </div>
            </div>
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <div :class="theme.statusrow.subheaderText">Daily P&L</div>
                    <div :class="[s.tableValue, { [s.green]: currentPnlValue > 0, [s.red]: currentPnlValue < 0}]">
                        {{ currentPnlSign }}{{ quotationAssetCharacter + Math.abs(currentPnlValue || 0).toFixed(quotationPrecision).getSeparatedDigits() }}
                    </div>
                </div>
            </div>
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <div :class="theme.statusrow.subheaderText">Period P&L</div>
                    <div :class="[s.tableValue, { [s.green]: summaryPeriodPnl > 0, [s.red]: summaryPeriodPnl < 0}]">
                        {{ summaryPeriodPnlSign }}{{ quotationAssetCharacter + Math.abs(summaryPeriodPnl).toFixed(quotationPrecision).getSeparatedDigits() }}
                    </div>
                </div>
            </div>
            <div :class="theme.statusrow.contentColumn">
                <div>
                    <div :class="theme.statusrow.subheaderText">Unrealized P&L</div>
                    <div :class="[s.tableValue, { [s.green]: unrealizedPnl > 0, [s.red]: unrealizedPnl < 0}]">
                        {{ unrealizedPnlSign }}{{ quotationAssetCharacter }}{{ unrealizedPnl > 0 ? unrealizedPnl.toFixed(quotationPrecision).getSeparatedDigits() : (unrealizedPnl * (-1)).toFixed(quotationPrecision).getSeparatedDigits() }}
                    </div>
                </div>
            </div>
        </div>
        <HeaderSwitcher
            id="segregatedAndAggregatedAnalyticsButtons"
            :class="s.toggler"
            :active-index="portfolioTypeIndex"
            first-variant-text="Current Account"
            :second-variant-text="`All ${isActiveAccountOwned ? 'Owned' : 'Managed'} Accounts`"
            @change="portfolioTypeIndex = $event"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { PORTFOLIO_TYPES } from 'Models/portfolio';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import Icon from 'UI/Icon.vue';
import theme from 'Theme';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import { UnifiedExchangesTags } from 'Store/v2/Transfer';

export default {
    name: 'TotalAccount',
    components: {
        HeaderSwitcher,
        Icon,
    },
    data() {
        return {
            masks: {
                input: 'YYYY-MM-DD h:mm A',
            },
            hidePopoverFunction: null,
            portfolioTypeIndex: 0,
            PORTFOLIO_TYPES,
            MILLISECONDS_IN_DAY: 86400000,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            currentPnlValue: 'Accounts/activeAccountDailyPnl',
            isActiveAccountOwned: 'Accounts/isActiveAccountOwned',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            activeAccountId: 'Accounts/activeAccountID',
            ownedAccounts: 'Accounts/ownAccounts',
            managedAccounts: 'Accounts/manageAccounts',
        }),
        currentPnlSign() {
            if (this.currentPnlValue > 0) {
                return '+';
            }
            if (this.currentPnlValue < 0) {
                return '-';
            }
            return '';
        },
        summaryPeriodPnlSign() {
            if (this.summaryPeriodPnl > 0) {
                return '+';
            }
            if (this.summaryPeriodPnl < 0) {
                return '-';
            }
            return '';
        },
        unrealizedPnlSign() {
            if (this.unrealizedPnl > 0) {
                return '+';
            }
            if (this.unrealizedPnl < 0) {
                return '-';
            }
            return '';
        },
        summaryPeriodPnl() {
            return this.$store.state.Portfolio.summaryPeriodPnl;
        },
        range: {
            get() {
                return this.$store.getters['Portfolio/range'];
            },
            async set(range) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch('Portfolio/setRange', range);
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            },
        },
        portfolioType: {
            get() {
                return this.$store.state.Portfolio.portfolioType;
            },
            set(type) {
                this.$store.dispatch('Portfolio/setPortfolioType', type);
            },
        },
        quotationPrecision() {
            let precision;
            switch (this.quotationAssetSymbol) {
                case 'USD': {
                    precision = 2;
                    break;
                }
                case 'EUR': {
                    precision = 2;
                    break;
                }
                case 'BTC': {
                    precision = 8;
                    break;
                }
                case 'ETH': {
                    precision = 8;
                    break;
                }
                default: {
                    precision = 2;
                    break;
                }
            }
            return precision;
        },
        unrealizedPnlObject() {
            return this.$store.state.Portfolio.unrealizedPnl;
        },
        unrealizedPnl() {
            if (this.portfolioType === PORTFOLIO_TYPES.CURRENT_ACCOUNT) {
                if (this.$store.state.Portfolio.unrealizedPnl[this.activeAccountId]) {
                    return Number(this.unrealizedPnlObject[this.activeAccountId][this.quotationAssetSymbol]);
                }
                return 0;
            }
            return Array.from(Object.keys(this.unrealizedPnlObject)).reduce((accum, current) => accum + Number(this.unrealizedPnlObject[current][this.quotationAssetSymbol]), 0);
        },
        ownedAccountsTotal() {
            if (!this.ownedAccounts || this.ownedAccounts.length === 0) {
                return 0;
            }
            const idsSet = new Set();
            this.ownedAccounts.forEach((a) => {
                idsSet.add(a.id);
            });
            try {
                return this.$store.state.Balances.balances
                    .reduce((accum, { total, quotations, quotationAssetSymbols, accountId, placementName }) => {
                        const currentPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags.get(placementName.toUpperCase());
                        const currentPlacementType = this.$store.state.Placements.placements.find(({ name }) => name.toUpperCase() === placementName.toUpperCase())?.type;
                        if (
                            !idsSet.has(accountId)
                            || (
                                UnifiedExchangesTags.has(currentPlacementTag)
                                && currentPlacementType !== 'crypto-spot'
                            )
                        ) {
                            return accum;
                        }

                        const quotationIndex = quotationAssetSymbols.findIndex((symbol) => symbol === this.quotationAssetSymbol);
                        return accum + (quotationIndex !== -1 ? (Number(total) * Number(quotations[quotationIndex])) : 0);
                    }, 0);
            } catch (error) {
                return 0;
            }
        },
        managedAccountsTotal() {
            if (!this.managedAccounts || this.managedAccounts.length === 0) {
                return 0;
            }
            const idsSet = new Set();
            this.managedAccounts.forEach((a) => {
                idsSet.add(a.id);
            });
            try {
                return this.$store.state.Balances.balances
                    .reduce((accum, { total, quotations, quotationAssetSymbols, accountId, placementName }) => {
                        const currentPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags.get(placementName.toUpperCase());
                        const currentPlacementType = this.$store.state.Placements.placements.find(({ name }) => name.toUpperCase() === placementName.toUpperCase())?.type;
                        if (
                            !idsSet.has(accountId)
                            || (
                                UnifiedExchangesTags.has(currentPlacementTag)
                                && currentPlacementType !== 'crypto-spot'
                            )
                        ) {
                            return accum;
                        }
                        const quotationIndex = quotationAssetSymbols.findIndex((symbol) => symbol === this.quotationAssetSymbol);
                        return accum + (quotationIndex !== -1 ? (Number(total) * Number(quotations[quotationIndex])) : 0);
                    }, 0);
            } catch (error) {
                return 0;
            }
        },
        totalAccountScore() {
            if (this.portfolioType === PORTFOLIO_TYPES.CURRENT_ACCOUNT) {
                const currentAccount = this.$store.getters['Accounts/activeAccount'];
                if (currentAccount) {
                    return this.$store.getters['Balances/GET_ACCOUNT_TOTAL_QUOTED'](currentAccount.id);
                }
                return 0;
            }

            const { isOwned } = this.$store.getters['Accounts/activeAccount'];
            return isOwned ? this.ownedAccountsTotal : this.managedAccountsTotal;
        },
    },
    methods: {
        onDatePickerInput() {
            if (this.hidePopoverFunction !== null) {
                this.hidePopoverFunction();
            }
        },
        onDatePickerToggle(toggle, hide) {
            toggle();
            this.hidePopoverFunction = hide;
        },
        generateDropdownString({ start, end }) {
            const parsedStart = start
                .replace(' ', '-')
                .split('-')
                .filter((_, index) => index < 3)
                .join('-');
            const parsedEnd = end
                .replace(' ', '-')
                .split('-')
                .filter((_, index) => index < 3)
                .join('-');
            if (!!new Date(parsedStart) && !!new Date(parsedEnd)) {
                return `${this.$store.getters.getTimeDateString({ timestamp: parsedStart, showTime: false })} - ${this.$store.getters.getTimeDateString({ timestamp: parsedEnd, showTime: false })}`;
            }
            return 'Period';
        },
    },
    beforeDestroy() {
        this.$store.dispatch('Portfolio/setPortfolioType', PORTFOLIO_TYPES.CURRENT_ACCOUNT);
    },
    watch: {
        portfolioTypeIndex(val) {
            if (val === 0) {
                this.portfolioType = PORTFOLIO_TYPES.CURRENT_ACCOUNT;
            } else {
                this.portfolioType = PORTFOLIO_TYPES.ALL_GROUP_ACCOUNTS;
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    align-items: center;
    height: 100%;
}
.calendarContainer {
    display: flex;
    align-items: center;

    & * {
        color: var(--cl-black);
    }
}
.title {
    font-weight: var(--fw-bold);
    font-size: var(--fs-l);
    color: var(--cl-gray);
    flex-shrink: 0;
    flex-wrap: 0;
    margin-right: var(--m-xl);
}
.calendar {
    display: flex;
    align-items: center;
    column-gap: var(--m-s);
    cursor: pointer;
}
.icon {
    color: var(--cl-violet);
    width: 24px;
    height: 24px;
}
.dateText {
    font-weight: var(--fw-regular);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-black);
    margin-top: 1px;
}
.table {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
    column-gap: var(--m-xxl);
    justify-content: space-between;
    margin: 0 auto;
}
.tableValue {
    font-weight: var(--fw-semibold);
    font-size: var(--fs-l);
    white-space: nowrap;
}
.green {
    color: var(--cl-green);
}
.red {
    color: var(--cl-red);
}
.toggler {
    margin-left: auto;
}
.option {
    background-color: transparent;
    padding: var(--m-s) var(--m-xl);
    font-size: var(--fs-xl);
    font-weight: var(--fw-bold);
    color: var(--cl-gray);
    text-transform: uppercase;
    cursor: pointer;

    input {
        position: absolute;
        appearance: none;
        &:checked + span {
            color: var(--cl-violet);
        }
    }
}
.row {
    display: flex;
    align-items: center;
    column-gap: var(--m-m);
}
.mainColumn {
    padding: var(--m-xs) var(--m-m);
    border-radius: var(--m-s);
    border: 2px solid var(--cl-violet);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
</style>
